import { useRouter } from 'next/router';
import Image from 'next/image';
import { useEffect, useState, memo } from 'react';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import home from '../../../public/icon-menu-home-disabled.svg';
import homeActive from '../../../public/icon-menu-home.svg';
import ticket from '../../../public/icon-menu-tickets-disabled.svg';
import ticketActive from '../../../public/icon-menu-tickets.svg';
import play from '../../../public/icon-menu-play-disabled.svg';
import playActive from '../../../public/icon-menu-play.svg';
import menu from '../../../public/icon-menu-my-disabled.svg';
import menuActive from '../../../public/icon-menu-my.svg';

const BottomNav = () => {
  const router = useRouter();
  const [navHref, setNavHref] = useState<string>('');

  useEffect(() => {
    if (location.pathname === '/') {
      setNavHref('home');
    }
    if (location.href.includes('ticket')) {
      setNavHref('ticket');
    }
    if (location.href.includes('play')) {
      setNavHref('play');
    }
    if (location.href.includes('users')) {
      setNavHref('users');
    }
  }, []);

  return (
    <>
      <NavContainer>
        <div>
          <IconButton onClick={() => router.push('/')}>
            <Image src={navHref === 'home' ? homeActive : home} alt="home" width="20" height="20" />
          </IconButton>
          <span className={navHref === 'home' ? 'active' : ''}>HOME</span>
        </div>

        <div>
          <IconButton onClick={() => router.push('/ticket/park')}>
            <Image src={navHref === 'ticket' ? ticketActive : ticket} alt="tickets" width="20" height="20" />
          </IconButton>
          <span className={navHref === 'ticket' ? 'active' : ''}>TICKETS</span>
        </div>

        <div>
          <IconButton onClick={() => router.push('/play')}>
            <Image src={navHref === 'play' ? playActive : play} alt="play" width="20" height="20" />
          </IconButton>
          <span className={navHref === 'play' ? 'active' : ''}>PLAY</span>
        </div>

        <div>
          <IconButton onClick={() => router.push('/users')}>
            <Image src={navHref === 'users' ? menuActive : menu} alt="my" width="20" height="20" />
          </IconButton>
          <span className={navHref === 'users' ? 'active' : ''}>MY</span>
        </div>
      </NavContainer>
    </>
  );
};

export default memo(BottomNav);

const NavContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  position: fixed;
  bottom: 0;
  height: 56px;
  text-align: center;
  border-top: 1px solid #0000000a;
  background-color: #ecedee;
  width: 100%;
  max-width: 640px;
  z-index: 20;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-size: 10px;
      font-family: Montserrat;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.33);
      &.active {
        font-weight: 800;
        color: rgba(0, 0, 0, 1);
      }
    }

    img {
      width: 18px;
      margin-bottom: 4px;
    }
  }
`;
